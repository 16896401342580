<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="4">
                    <h2><strong>Learners Attendance Report</strong></h2>
                </b-col>
            </b-row>
            <div>
                <b-card no-body>
                    <b-tabs card>
                        <b-tab title="Daily Report" active>
                            <b-card-text>
                                <b-row>
                                    <b-col key="UserIcon" md="3" sm="6" class="m-1 border p-2 bg-info text-white">
                                        <b-media no-body class="">
                                            <b-media-aside class="mr-2">
                                                <b-avatar size="48" variant="light-white">
                                                    <feather-icon size="24" icon="UserIcon"/>
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <h4 class="font-weight-bolder mb-0 text-white">
                                                    {{ no_of_students }}
                                                </h4>
                                                <b-card-text class="font-small-3 mb-0">
                                                    Learners
                                                </b-card-text>
                                            </b-media-body>
                                        </b-media>
                                    </b-col>

                                    <b-col key="users" md="3" sm="6" class="m-1 border p-2 bg-success text-white">
                                        <b-media no-body>
                                            <b-media-aside class="mr-2">
                                                <b-avatar size="48" variant="light-transparent">
                                                    <feather-icon size="24" icon="users"/>
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <h4 class="font-weight-bolder mb-0 text-white">
                                                    {{ present }}
                                                </h4>
                                                <b-card-text class="font-small-3 mb-0">
                                                    Learners Present
                                                </b-card-text>
                                            </b-media-body>
                                        </b-media>
                                    </b-col>

                                    <b-col key="TrendingUpIcon" md="3" sm="6" class="m-1 border p-2 bg-warning text-white">
                                        <b-media no-body>
                                            <b-media-aside class="mr-2">
                                                <b-avatar size="48" variant="light-white">
                                                    <feather-icon size="24" icon="TrendingUpIcon"/>
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <h4 class="font-weight-bolder mb-0 text-white">
                                                    {{ absent }}
                                                </h4>
                                                <b-card-text class="font-small-3 mb-0">
                                                    Learners Absent
                                                </b-card-text>
                                            </b-media-body>
                                        </b-media>
                                    </b-col>
                               
                                    <div class="col-md-6 col-12">
                                        <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        placeholder="Type to Search"
                                        class="my-1"
                                        ></b-form-input>
                                    </div>
                                </b-row>
                            
                                <b-table
                                    responsive
                                    :items="items"
                                    :fields="fields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :bordered="bordered" 
                                    :hover="hover"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    id="my-table"
                                    small
                                    @filtered="onFiltered"
                                >   
                                    <template #cell(arrival_date)="data">
                                        {{ data.item.arrival_date | setDate }} <br> {{ data.item.arrival_staff }} 
                                    </template>

                                    <template #cell(departed_date)="data">
                                        {{ data.item.departed_date | setDate }} <br> {{ data.item.departure_staff }} 
                                    </template>
                                </b-table>
                    
                                <b-row class="mb-2">
                                    <b-col md="4">
                                        <b-form-group
                                            label="Showing"
                                            label-for="per-page-select"
                                            label-cols-md="4"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                        >
                                            <b-form-select
                                            id="per-page-select"
                                            v-model="perPage"
                                            :options="pageOptions"
                                            size="sm"
                                            class="def"
                                            ></b-form-select>
                                            of {{ items.length }} Records
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-pagination
                                            aria-controls="my-table"
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                            size="sm"
                                            class="my-0"
                                        ></b-pagination>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>

                        <b-tab title="Termly Report">
                            <b-card-text>
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search Learner"
                                    class="my-1">
                                </b-form-input>
                               
                                <b-table
                                    responsive
                                    :items="itemsTerm"
                                    :fields="fieldsTerm"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :bordered="bordered" 
                                    :hover="hover"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    id="my-table"
                                    small
                                    @filtered="onFiltered"
                                >   

                                    <template #cell(arrival_date)="data">
                                        {{ data.item.arrival_date | setDate }} <br> {{ data.item.arrival_staff }} 
                                    </template>

                                    <template #cell(departed_date)="data">
                                        {{ data.item.departed_date | setDate }} <br> {{ data.item.departure_staff }} 
                                    </template>
                                </b-table>
                    
                                <b-row class="mb-2">
                                    <b-col md="4">
                                        <b-form-group
                                            label="Showing"
                                            label-for="per-page-select"
                                            label-cols-md="4"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                        >
                                            <b-form-select
                                            id="per-page-select"
                                            v-model="perPage"
                                            :options="pageOptions"
                                            size="sm"
                                            class="def"
                                            ></b-form-select>
                                            of {{ itemsTerm.length }} Records
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-pagination
                                            aria-controls="my-table"
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                            size="sm"
                                            class="my-0"
                                        ></b-pagination>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>

                        <b-tab title="Session Report">
                            <b-card-text>
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search Learner"
                                    class="my-1">
                                </b-form-input>

                                <b-table
                                    responsive
                                    :items="itemsSession"
                                    :fields="fieldsSession"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :bordered="bordered" 
                                    :hover="hover"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    id="my-table"
                                    small
                                    @filtered="onFiltered"
                                >   

                                    <template #cell(arrival_date)="data">
                                        {{ data.item.arrival_date | setDate }} <br> {{ data.item.arrival_staff }} 
                                    </template>

                                    <template #cell(departed_date)="data">
                                        {{ data.item.departed_date | setDate }} <br> {{ data.item.departure_staff }} 
                                    </template>
                                </b-table>
                    
                                <b-row class="mb-2">
                                    <b-col md="4">
                                        <b-form-group
                                            label="Showing"
                                            label-for="per-page-select"
                                            label-cols-md="4"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                        >
                                            <b-form-select
                                            id="per-page-select"
                                            v-model="perPage"
                                            :options="pageOptions"
                                            size="sm"
                                            class="def"
                                            ></b-form-select>
                                            of {{ itemsSession.length }} Records
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-pagination
                                            aria-controls="my-table"
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                            size="sm"
                                            class="my-0"
                                        ></b-pagination>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import { Form } from 'vform';
    
    export default {
        data() {
            return {
                is_busy: false,
                editMode: false,
                title: '',
                items: [],
                fields: [
                    { key: 'student', label: 'student'},
                    { key: 'class', label: 'Class'},
                    { key: 'arrival_date', label: 'Arrival Date'},
                    { key: 'departed_date', label: 'departed_date'},
                ],

                itemsTerm: [],
                fieldsTerm: [
                    { key: 'student', label: 'student'},
                    { key: 'class', label: 'Class'},
                    { key: 'arrival_date', label: 'Arrival Date'},
                    { key: 'departed_date', label: 'departed_date'},
                ],

                itemsSession: [],
                fieldsSession: [
                    { key: 'student', label: 'student'},
                    { key: 'class', label: 'Class'},
                    { key: 'arrival_date', label: 'Arrival Date'},
                    { key: 'departed_date', label: 'departed_date'},
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 15,
                bordered: true,
                hover: true,
                fixed: true,
                pageOptions: [5, 10, 15, 25, 50, 100, { value: 100, text: "All" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
                no_of_students: '',
                absent: '',
                present: '',
                is_busy: false,
                editmode: false,
              
                action: {
                    selected: [],
                    id: '',
                },
            }
        },

        created() {
            this.roleHelper(['1', '5']);
            // this.featureHelper(16);
            this.load();
        },

        methods: {
            load() {
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/attendance')
                .then(res => {
                    this.items = res.data.data.attendances;
                    this.itemsTerm = res.data.data.attendancesTerm;
                    this.itemsSession = res.data.data.attendancesSession;
                    this.present = res.data.data.present;
                    this.absent = res.data.data.absent;
                    this.no_of_students = res.data.data.no_of_students;
                    // this.totalRows = this.items.length
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },

        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                .filter(f => f.sortable)
                .map(f => {
                return { text: f.label, value: f.key }
                })
            },

            selectAll: {
                get: function () {
                    return this.items ? this.action.selected.length == this.items.length : false;
                },

                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.items.forEach(function (room) {
                            selected.push(room.id);
                        });
                    }

                    this.action.selected = selected;
                }
            }
        },
    }
</script>
<style>
    .cont {
        background: #F3F2F7;
    }

    .def {
        width: 70px;
    }
</style>